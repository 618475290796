<template>
	<mw-dialog
		v-model="dialog"
		submitBtn
		cancelBtn
		@submit="submit"
		:disabled="!no_recommendation && !email"
		:title="$t('assessment.reject')"
	>
		<template v-slot:button>
			<v-btn v-if="icon" icon @click="dialog = true">
				<v-icon v-if="icon">mdi-check</v-icon>
			</v-btn>
			<v-list-item v-else @click="dialog = true">
				<v-list-item-title>
					{{ $t("assessment.reject") }}
				</v-list-item-title>
			</v-list-item>
		</template>
		<template v-slot:dialog-body>
			<p>{{ $t("messages.before_reject") }}</p>
			<v-row>
				<v-col>
					<v-text-field v-model="name" label="Name" :disabled="no_recommendation"></v-text-field>
				</v-col>
				<v-col>
					<v-text-field v-model="email" label="Email" :disabled="no_recommendation"></v-text-field>
				</v-col>
			</v-row>
			<v-textarea v-model="comments" label="Your Comments"></v-textarea>
			<v-checkbox
				dense
				hide-details
				v-model="no_recommendation"
				label="I don't know who should complete this section"
			></v-checkbox>
		</template>
	</mw-dialog>
</template>

<style lang="less">
	.button {
		cursor: pointer;
	}
</style>
<script>
	import MwDialog from "@c/ui/MwDialog";

	export default {
		name: "Reject",
		props: {
			id: {type: String}, // the ID of the response,
			icon: {type: Boolean, default: false}
		},
		data: () => {
			return {
				dialog: false,
				email: null,
				name: null,
				no_recommendation: false,
				comments: null
			};
		},
		computed: {
			response() {
				return this.$store.state.sectionResponses.data[this.id];
			},
			user() {
				return this.$store.getters["auth/id"];
			}
		},
		components: {
			MwDialog
		},
		methods: {
			submit() {
				this.$store.dispatch("rejectDelegation", {
					response: this.id,
					no_recommendation: this.no_recommendation,
					email: this.email,
					comments: this.comments,
					name: this.name
				});
			}
		}
	};
</script>
